<template>
	<router-view />
</template>

<script>
import 'animate.css';

export default {
	name: "App",
	mounted() {
		const originUrl = window.location.href;

		if (window.innerWidth > 1024 && !originUrl.includes('/terms') && !originUrl.includes('/tcs')) {
			const originUrl = window.location.href;
			this.$router.push({ path: '/mobile-only', query: { origin: originUrl } });
		}
	}
};
</script>

<style>
:root {
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}
@supports (font-variation-settings: normal) {
	:root {
		font-family: "Inter", sans-serif;
	}
}

.font-inter {
	font-family: "Inter", sans-serif;
	font-weight: 500;
}

html,
body {
	margin: 0;
	padding: 0;
	background: #ffffff;
	webkit-font-smoothing: antialiased;
}

html {
	height: -webkit-fill-available;
}
body {
	padding: 0;
	margin: 0;
	min-height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
	body {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}
}

#app {
	min-height: 100vh;
	margin: 0 auto;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
	#app {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}
}

.full-height {
	height: 100vh;
}

#view-desktop .full-height {
	height: 100% !important;
}

.min-full-height {
	min-height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
	.min-full-height {
		/* The hack for Safari */
		min-height: -webkit-fill-available;
	}
	.full-height {
		/* The hack for Safari */
		height: -webkit-fill-available;
	}
}

hr {
	border-bottom: 1px solid #eceef0;
	margin-top: 16px;
	margin-bottom: 16px;
}
</style>
