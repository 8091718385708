// Vue.use(VueRouter);
import { createWebHistory, createRouter } from "vue-router";

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("./views/Home.vue"),
	},
	{
		path: "/onboarding",
		name: "video",
		component: () => import("./views/Video.vue"),
	},
	{
		path: "/v2/:linkToken",
		name: "fetch-link-v2",
		component: () => import("./views/Fetch_V2.vue"),
	},
	{
		path: "/:linkToken",
		name: "fetch-link",
		component: () => import("./views/Fetch.vue"),
	},
	{
		path: "/create",
		name: "create",
		component: () => import("./views/Create.vue"),
	},
	{
		path: "/sharing/:linkToken",
		name: "sharing",
		component: () => import("./views/Sharing.vue"),
	},
	{
		path: "/mobile-only",
		name: "mobile-only",
		component: () => import("./views/MobileOnly.vue"),
		props: route => ({ originUrl: route.query.origin })
	},
	{
		path: "/account",
		name: "account",
		component: () => import("./views/Account.vue"),
	},
	{
		path: "/account-update",
		name: "account-update",
		component: () => import("./views/AccountUpdate.vue"),
	},
	{
		path: "/account-update-us",
		name: "account-update-us",
		component: () => import("./views/AccountUpdateUS.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("./views/Login.vue"),
	},
	{
		path: "/auth",
		name: "auth",
		component: () => import("./views/Auth.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("./views/Terms.vue"),
	},
	{
		path: "/tcs",
		name: "tcs",
		component: () => import("./views/TCs.vue"),
	},
	{
		path: "/verify-account",
		name: "verify-account",
		component: () => import("./views/Verify.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
