// src/store/index.js
import { createStore } from "vuex";

export default createStore({
  state: {
    jwt: localStorage.getItem("userToken") || null // Initialize the state from localStorage
  },
  mutations: {
    setJwt(state, token) {
      state.jwt = token;
    },
    clearJwt(state) {
      state.jwt = null;
    }
  },
  actions: {
    saveJwt({ commit }, token) {
      localStorage.setItem("userToken", token); // Save token to localStorage
      commit("setJwt", token); // Save token to Vuex state
    },
    logout({ commit }) {
      localStorage.removeItem("userToken"); // Remove token from localStorage
      commit("clearJwt"); // Clear Vuex state
    }
  },
  getters: {
    isLoggedIn: state => !!state.jwt
  }
});
